import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de pota d’animal de consistència llenyosa, de 40 cm de llargària per 20 d’amplària, molt enganxat a la soca de l’arbre on creix. Format per vàries capes concèntriques; l’exterior blanquinosa o cremosa, la d’enmig color groc taronjós i la més interna negrosa. La superfície inferior està coberta de porus molt fins de color crema clars al principi, obscurint-se després. Les espores són cilíndriques, llises, de 25-50 x 17-40 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      